import React from 'react';
import NotFound from '@bahaide/shared/components/NotFound';
import { StaticQuery, graphql } from 'gatsby';

function NotFoundPage() {
    return (
        <StaticQuery
            query={graphql`
                query {
                    site {
                        siteMetadata {
                            defaultTitle: title
                            titleTemplate
                            defaultDescription: description
                            siteUrl
                            defaultImage: image
                            twitterUsername
                        }
                    }
                }
            `}
            render={data => <NotFound metadata={data.site.siteMetadata} />}
        />
    );
}

export default NotFoundPage;
