import React from 'react';

import { PrimaryInnerPage, TwoColumns, Sidebar, Content } from './InnerPage';

const NotFound = ({ metadata }) => (
    <PrimaryInnerPage title="Seite nicht gefunden" metadata={metadata}>
        <TwoColumns>
            <Content>
                <div class="head">
                    <h1>Seite wurde nicht gefunden</h1>
                </div>
                <p>
                    Bitte benutzen Sie das Menü oder die Suche um weiter zu
                    navigieren.
                </p>
                <form method="get" className="search-form" action="/search">
                    <fieldset>
                        <input
                            type="search"
                            name="q"
                            placeholder=""
                            defaultValue=""
                        />
                        <button>
                            <i
                                className="fa fa-search fa-rotate-90"
                                aria-hidden="true"
                            />
                        </button>
                    </fieldset>
                </form>
            </Content>
            <Sidebar />
        </TwoColumns>
    </PrimaryInnerPage>
);

export default NotFound;
